.form div {
  /* font-size: 18px; */
}
.question {
  display: flex;
  gap: 10;
  margin-bottom: 15px;
  padding-bottom: 10px;
  align-items: center;
  /* border-bottom: 2px solid rgba(169, 169, 169, 0.332); */
}
.question .label {
  margin-right: 10px;
  font-size: 15px;
}
.label::before {
  /* content:"Q. "; */
  font-size: 30px;
}
.label {
  max-width: 70%;
}
.question .answer {
}
.entry-question {
  margin-bottom: 5px;
}
.entriesDiv {
  margin: 10px;
  padding: 10px;
  border-left: 3px solid darkgrey;
  font-size: 13px;
}

.removeButton {
  background-color: lightcoral;
  color: white;
}

.entriesDiv {
  margin-top: 20px;
}

.entryDiv {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.entryHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.entryContent {
  margin-top: 10px;
}

.entryQuestion {
  margin-bottom: 5px;
  margin-top: 15px;
  align-items: flex-end;
}

.label {
  flex: 1;
}

.answer {
  flex: 2.5;
}

.formx .ant-input,
.formx .ant-picker,
.formx .ant-input-affix-wrapper,
.formx .ant-select {
  width: 400px !important;
}

.addButton {
  margin-top: 10px;
}
