.label {
  font-weight: 500;
  font-size: 20px !important;
  align-items: center;
  align-content: center;
  gap: 10;
  max-width: 100% !important;
  margin-bottom: 10px !important;
}
.question{
  flex-direction: column;
}
.question .label, .question .answer{
  width: 100% !important;
}

/* .question .label{
    width: 300px;
} */

/* @media (max-width: 600px) {
  .label {
    font-size: smaller !important;
  }
} */
.entryQuestion{
  align-items: start !important;
}
.entryQuestion .label{
  font-size: 17px !important;
}