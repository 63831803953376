body {
  /* background-color: #EBDFD7; */
}

/*FLEX1*/

.flex1,
.flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.e {
  justify-content: space-between;
}

.flexbtn {
  display: flex;
  align-items: center;
  gap: 5px;
}

.full-width-space.ant-space, .full-width-space.ant-space *{
  width: 100%;
}
