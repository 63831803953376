.container {
  width: 30%;
  margin: auto;
  padding: 10px;
}

.card-container {
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  min-width: 100%;
}
.step-title {
  transform: translateX(-50px);
}
@media (max-width: 768px) {
  .container {
    width: 100%;
  }

  .card-container {
    min-width: 90%;
    margin: 0 auto;
  }
  .step-title {
    transform: translateX(0px);
  }
}
