.form-container {
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.checkbox-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.checkbox-field {
  font-size: 16px;
}

.results-container {
  margin-top: 20px;
}

.result-item {
  display: flex;
  padding: 8px 0;
  font-size: 16px;
  border-bottom: 1px solid #f0f0f0;
}

/* Responsive design */
@media (max-width: 600px) {
  .form-container {
    margin: 10px;
    padding: 15px;
  }

  .checkbox-field {
    font-size: 16px;
  }

  .result-item {
    font-size: 16px;
  }
}
