.user-body {
  background-color: #ebdfd7;
  min-height: 100vh;
  padding: 40px;
  border: 17px solid rgba(100, 153, 51, 0.445);
}
.user-options {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.user-option {
  border: 1px solid lightgrey;
  background: inherit;
  position: relative;
  display: flex;
  align-items: left;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  width: 300px;
  /* text-align: center; */
  padding-left: 30px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.426);
}
.user-option .icon {
  font-size: 30px;
  color: aliceblue;
  border-radius: 100%;
  background-color: #187fc4;
  width: fit-content;
  padding: 10px;
  line-height: 0px;
  /* width: 50px !important;
  height: 50px; */
}
.title0 {
  color: rgb(138, 137, 137) !important;
  text-transform: uppercase;
}
.user-option::before {
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
  filter: blur(10px);
}
.user-option:hover {
  background-color: rgba(255, 255, 255, 0.726);
}
.user-option.disabled {
  background-color: #f2f2f2; /* Light grey background */
  color: #999999; /* Light grey text color */
  cursor: not-allowed; /* Change cursor to indicate non-interactive */
  filter: grayscale(1);
}

.user-option.done {
  /* background-color: cadetblue; */
}
.mobile .user-option {
  padding: 14px;
  width: 100% !important;
}

@media (max-width: 600px) {
  .user-body {
    padding: 15px;
    border: 3px solid darkblue;
  }
  .user-option {
  }
  .icon{
  }
}
