.summary {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.25),
    rgba(255, 255, 255, 0.05)
  );
  backdrop-filter: blur(10px);

  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.summary-section {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 24px;
}

.summary-card {
  flex: 1 1 200px;
  min-width: 200px;
  max-width: 300px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: transform 0.3s, box-shadow 0.3s;
}

.summary-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.2);
}
