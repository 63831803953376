.externalwebsitebox {
  width: 100%;
  min-height: 12vh;
  padding: 10px;
  border: 1px solid darkblue;
  background-color: rgba(230, 230, 250, 0.658);
}
.externalwebsitebox:hover {
  cursor: pointer;
  background-color: rgba(230, 230, 250, 1);
}

.disabled {
  background-color: #f2f2f2; /* Light grey background */
  color: #999999; /* Light grey text color */
  cursor: not-allowed; /* Change cursor to indicate non-interactive */
  filter: grayscale(1);
}

.externalwebsitebox:has(.is_uploaded) {
  /* background-color: lightgreen; */
}
