.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height: 100vh; */
  flex-direction: column;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-input {
  margin: 10px 0px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 340px;
}

.login-button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  width: 390px;
}
.login-button:disabled {
  background-color: #62aefe;
}
.login-logo {
  margin-bottom: 20px; /* Adjust the space below the logo */
  width: 150px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
}
