.home {
  border: 17px solid rgba(100, 153, 51, 0.445);
  border-bottom: 0px;
}
tr {
  background-color: white;
}
.row-medicaid {
  background-color: #d9ead3; /* Soft green, reminiscent of Excel's financial templates */
}

.row-medicare {
  background-color: #cfe2f3; /* Soft blue, similar to Excel's default table styles */
}

.row-snap {
  background-color: #fce5cd; /* Soft orange, often used for highlights in Excel */
}

.row-medicare,
.row-snap,
.row-medicaid {
  background-color: #d9ead3; /* Soft green, reminiscent of Excel's financial templates */
}

tr td {
  background-color: inherit !important;
}

.round {
  width: 20px; /* Set the width of the div */
  height: 20px; /* Set the height of the div */
  border-radius: 50%; /* This creates the round shape */
}
